import Icon from '@mui/icons-material/Help'
import { grey } from '@mui/material/colors'
import { useMemo } from 'react'

export const HelpIcon = (props) => {
    return useMemo(
        () => <Icon sx={{ color: grey[50], marginX: '10px' }} {...props} />,
        [props]
    )
}
