import React from 'react'

export const Context = React.createContext({})
export const Provider = ({ children }) => {
    const value = {}

    return <Context.Provider value={value}>{children}</Context.Provider>
}

const defaults = {
    Context,
    Provider,
}

export default defaults
