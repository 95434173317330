import * as React from 'react'
import styled from '@emotion/styled'

import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import { DialogActions, DialogContent, Grid } from '@mui/material'
import { Navigation, Pagination, A11y } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { buttonBaseColor } from 'constants/colors'
import guideImage1 from 'assets/paper.png'
import guideImage2 from 'assets/picture.png'
import guideImage3 from 'assets/play.png'
import { GuideSlide } from './GuideSlide'
import { GuideSkipButton } from './GuideSkipButton'

const guideMapData = [
    {
        texts: ['ひらがなを書いた紙を', '用意しましょう'],
        image: guideImage1
    },
    {
        texts: ['アプリのカメラを起動して', '撮影しましょう'],
        image: guideImage2
    },
    {
        texts: ['再生してペーパーちゃんを', 'おしゃべりさせてみましょう'],
        image: guideImage3
    }
]

const GuideDialog = styled(Dialog)`
    .MuiDialog-paper {
        height: 80vh;
        height: 80dvh;
        max-height: 500px;
    }
`

export const UserGuideDialog = (props) => {
    const { onClose, open } = props
    const [finished, setFinished] = React.useState(false)

    const handleClose = React.useCallback(() => {
        onClose && onClose()
    }, [onClose])

    const handleSlideChange = React.useCallback((swiper) => {
        if (swiper.realIndex === guideMapData.length - 1) {
            setFinished(true)
        } else {
            setFinished(false)
        }
    }, [])

    return React.useMemo(
        () => (
            <GuideDialog
                fullWidth
                maxWidth='xs'
                onClose={handleClose}
                open={open}
            >
                <DialogContent>
                    <Grid width='100%' height='100%'>
                        <GuideSlide
                            modules={[Navigation, Pagination, A11y]}
                            autoplay={true}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                            onSlideChange={handleSlideChange}
                            onSwiper={handleSlideChange}
                            dataList={guideMapData}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions
                    sx={{
                        padding: 0,
                        height: '56px',
                        backgroundColor: buttonBaseColor
                    }}
                    disableSpacing={true}
                >
                    <GuideSkipButton
                        onClose={handleClose}
                        finished={finished}
                    />
                </DialogActions>
            </GuideDialog>
        ),
        [handleClose, open, handleSlideChange, finished]
    )
}

UserGuideDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
}
