import React, { useMemo } from 'react'
import styled from '@emotion/styled'

import { Grid, Typography } from '@mui/material'

const AppTitle = styled(Typography)`
    color: white;
    font-size: 21px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 1.25px;
`

const Title = ({ title }) => {
    return useMemo(() => <AppTitle>{title}</AppTitle>, [title])
}

export default Title
