// Import層
import React, { useEffect, useMemo } from 'react'
import { Route, BrowserRouter, Routes, useNavigate } from 'react-router-dom'

import { Provider } from 'contexts/siteContext'
import { Top, Capture, Login, Home, Logout } from 'views'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthProvider, useAuth } from 'lib/auth'

const queryClient = new QueryClient()

const PrivatePage = (props) => {
    const { user } = useAuth()
    const navigator = useNavigate()
    useEffect(() => {
        if (!user?.token) {
            navigator('/login')
        }
    }, [user, navigator])
    return useMemo(() => <>{props.children}</>, [props])
}

const Container = () => {
    const detectWebcam = (callback) => {
        let md = navigator.mediaDevices
        if (!md || !md.enumerateDevices) return callback(false)
        md.enumerateDevices().then((devices) => {
            callback(devices.some((device) => 'videoinput' === device.kind))
        })
    }

    //console.log(navigator)
    detectWebcam(console.log)

    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <Provider>
                    <BrowserRouter>
                        <Routes>
                            <Route
                                path={'/'}
                                element={
                                    <PrivatePage>
                                        <Top />
                                    </PrivatePage>
                                }
                            />
                            <Route path={'/login'} element={<Login />} />
                            <Route path={'/logout'} element={<Logout />} />
                            <Route
                                path={'/home'}
                                element={
                                    <PrivatePage>
                                        <Home />
                                    </PrivatePage>
                                }
                            />
                            <Route
                                path={'/capture'}
                                element={
                                    <PrivatePage>
                                        <Capture />
                                    </PrivatePage>
                                }
                            />
                        </Routes>
                    </BrowserRouter>
                </Provider>
            </AuthProvider>
        </QueryClientProvider>
    )
}

// Export層
export default Container
