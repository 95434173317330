import React, { useMemo } from 'react'

import { useAnalyze } from 'hooks'
import { CaptureTemplate } from 'templates'
import useCaptureContent from 'hooks/useCaptureContent'

const Container = () => {
    const {
        handleCapture,
        handlePlay,
        loading,
        error,
        success,
        playing,
        webcamRef,
        message
    } = useAnalyze()
    const { title, texts, charactor, buttons, color } = useCaptureContent({
        handleCapture,
        handlePlay,
        loading,
        error,
        success,
        playing
    })

    return useMemo(
        () => (
            <>
                <CaptureTemplate
                    title={title}
                    charactor={charactor}
                    texts={texts}
                    buttons={buttons}
                    color={color}
                    webcamRef={webcamRef}
                    loading={loading}
                    message={message}
                />
            </>
        ),
        [title, charactor, texts, buttons, color, webcamRef, loading, message]
    )
}

export default Container
