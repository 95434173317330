import React, { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'

import { Box, Button, Grid, Typography } from '@mui/material'
import Text from './text'
import charactor from 'assets/charactor.png'
import { useNavigate } from 'react-router-dom'
import {
    buttonBaseColor,
    buttonHoverColor,
    buttonDisabledBgColor,
    buttonDisabledFontColor
} from 'constants/colors'

const PrimaryButton = styled(Button)`
    background-color: ${buttonBaseColor};
    box-size: border-box;
    color: white;
    font-weight: bold;
    height: 56px;
    :hover {
        background-color: ${buttonHoverColor};
    }
    &.Mui-disabled {
        background-color: ${buttonDisabledBgColor};
        color: ${buttonDisabledFontColor};
    }
`
const SecondaryButton = styled(Button)`
    background-color: white;
    border: 1.5px solid ${buttonBaseColor};
    box-size: border-box;
    color: ${buttonBaseColor};
    font-weight: bold;
    height: 56px;
    :hover {
        background-color: white;
        border: 1.5px solid ${buttonHoverColor};
        color: ${buttonHoverColor};
    }
    &.Mui-disabled {
        background-color: ${buttonDisabledBgColor};
        border: none;
        color: ${buttonDisabledFontColor};
    }
`

export const BaseButton = ({ icon, onClick, disabled, children, type }) => {
    return useMemo(
        () => (
            <>
                {type === 'secondary' ? (
                    <SecondaryButton
                        variant='contained'
                        size='large'
                        startIcon={icon || null}
                        fullWidth
                        onClick={() => onClick()}
                        disabled={disabled}
                    >
                        {children}
                    </SecondaryButton>
                ) : (
                    <PrimaryButton
                        variant='contained'
                        size='large'
                        startIcon={icon || null}
                        fullWidth
                        onClick={() => onClick()}
                        disabled={disabled}
                    >
                        {children}
                    </PrimaryButton>
                )}
            </>
        ),
        [children, disabled, icon, onClick, type]
    )
}

BaseButton.defaultProps = {
    type: 'primary'
}

const PanelButton = styled(Button)`
    background-color: white;
    border: 1px solid ${buttonBaseColor};
    border-radius: 8px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%),
    box-size: border-box;
    padding: 16px;
    color: ${buttonBaseColor};
    font-weight: bold;
    min-width: 300px;
    :hover {
        background-color: rgba(255, 255, 255, 0.8);
    }
    &.Mui-disabled {
        background-color: rgba(255, 255, 255, 0.5);
        color: ${buttonBaseColor};
    }
`

export const AppButton = ({ icon, href, onClick, disabled, texts, char }) => {
    const navigator = useNavigate()
    const handleClick = useCallback(() => {
        onClick()
        href && navigator(href)
    }, [onClick, href, navigator])
    return useMemo(
        () => (
            <PanelButton
                fullWidth
                startIcon={icon || null}
                onClick={handleClick}
                disabled={disabled}
            >
                <Grid
                    container
                    height='130px'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Grid item xs={8} height='100%'>
                        <Grid
                            container
                            direction='column'
                            justifyContent='center'
                            alignItems='left'
                            height='100%'
                        >
                            {texts.map((text) => (
                                <Typography
                                    color={buttonBaseColor}
                                    textAlign='left'
                                    pl={2}
                                    fontSize={24}
                                    style={{ fontWeight: 'bold' }}
                                    key={text}
                                >
                                    {text}
                                </Typography>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={4} height='100%'>
                        <Grid
                            container
                            justifyContent='center'
                            alignItems='center'
                            height='100%'
                        >
                            <img
                                src={char || charactor}
                                alt='メインキャラクター'
                                height='70%'
                                style={{ maxHeight: '80px' }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </PanelButton>
        ),
        [disabled, icon, handleClick, texts, char]
    )
}

AppButton.defaultProps = {
    texts: ['アプリ名'],
    onClick: () => {},
    disabled: false
}
