import { Box, CircularProgress, Fade, Grid, Skeleton } from '@mui/material'
import { height, textAlign } from '@mui/system'
import { Logo } from 'components/logo'
import { baseBackgroundColor, buttonBaseColor } from 'constants/colors'
import { useEffect, useMemo, useState } from 'react'

export const LoginLoading = ({ loading }) => {
    const [isVisible, setIsVisible] = useState(false)
    useEffect(() => {
        if (!isVisible) return
        const wait = setTimeout(() => {
            setIsVisible(false)
        }, 2000)
        return () => clearTimeout(wait)
    }, [isVisible])
    useEffect(() => {
        if (loading) {
            setIsVisible(true)
        }
    }, [loading])
    return useMemo(
        () =>
            !isVisible ? null : (
                <>
                    <Fade appear={false} in={isVisible} timeout={1000}>
                        <Grid
                            container
                            justifyContent='center'
                            alignItems='center'
                            zIndex={5}
                            sx={{
                                backgroundColor: 'rgba(0,0,0,0.8)',
                                opacity: 0.7,
                                position: 'absolute',
                                top: '0px',
                                left: '0px',
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    width: '160px',
                                    height: '160px'
                                }}
                                zIndex={6}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        width: '100%',
                                        height: '100%'
                                    }}
                                >
                                    <CircularProgress
                                        size='100%'
                                        thickness={1.2}
                                        sx={{
                                            color: buttonBaseColor
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 'calc(50% - 8px)',
                                        left: 'calc(50% - 25%)',
                                        width: '50%'
                                    }}
                                >
                                    <Skeleton
                                        variant='rect'
                                        sx={{
                                            backgroundColor: 'rgba(0,0,0,0)'
                                        }}
                                    >
                                        ログイン中
                                    </Skeleton>
                                </Box>
                            </Box>
                        </Grid>
                    </Fade>
                </>
            ),
        [isVisible]
    )
}
