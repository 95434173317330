import React, { useMemo } from 'react'
import { AppsTemplate } from 'templates'

const Container = () => {
    return useMemo(
        () => (
            <>
                <AppsTemplate />
            </>
        ),
        []
    )
}

export default Container
