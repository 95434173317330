import React, { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'

import { Box, Button, Grid, Typography } from '@mui/material'
import { baseTextColor, buttonBaseColor } from 'constants/colors'
import defaultImage from 'assets/default.png'
import { useNavigate } from 'react-router-dom'
import { LoginIcon } from 'components/icon'

const Title = styled(Typography)`
    color: ${baseTextColor};
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 1.25px;
    text-align: center;
`

export const LogoutComplite = () => {
    const navigate = useNavigate()
    const handleLogin = useCallback(() => {
        navigate('/login')
    }, [navigate])
    return useMemo(
        () => (
            <Grid container justifyContent='center' alignItems='center'>
                <Grid
                    container
                    height='80%'
                    maxHeight='400px'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Grid
                        container
                        height='10%'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Title>ログアウトしました！</Title>
                    </Grid>
                    <Grid
                        container
                        height='35%'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <img
                            src={defaultImage}
                            alt='メインキャラクター'
                            height='80px'
                            style={{ maxHeight: '80px' }}
                        />
                    </Grid>

                    <Grid
                        container
                        height='10%'
                        justifyContent='center'
                        alignItems='center'
                        mx={2}
                    >
                        <Grid
                            container
                            justifyContent='center'
                            alignItems='center'
                        >
                            <Box
                                sx={{
                                    fontSize: '16px',
                                    letterSpacing: '1.25px',
                                    color: baseTextColor
                                }}
                            >
                                また遊びに来てね♪
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        height='25%'
                        justifyContent='center'
                        alignItems='center'
                    ></Grid>
                    <Grid
                        container
                        height='20%'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Button
                            fullWidth
                            onClick={handleLogin}
                            startIcon={<LoginIcon />}
                            sx={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: buttonBaseColor,
                                paddingY: '20px'
                            }}
                        >
                            ログインする
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        ),
        [handleLogin]
    )
}
