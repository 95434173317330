import { Fade, Grid } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { Logo } from './logo'
import { baseBackgroundColor } from 'constants/colors'

export const Splash = () => {
    const [isVisible, setIsVisible] = useState(true)
    useEffect(() => {
        const wait = setTimeout(() => {
            setIsVisible(false)
        }, 2000)
        return () => clearTimeout(wait)
    }, [])
    return useMemo(
        () => (
            <>
                <Fade appear={false} in={isVisible} timeout={1000}>
                    <Grid
                        container
                        justifyContent='center'
                        alignItems='center'
                        zIndex={5}
                        sx={{
                            backgroundColor: baseBackgroundColor,
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                            width: '100%',
                            height: '100%'
                        }}
                    >
                        <Logo />
                    </Grid>
                </Fade>
            </>
        ),
        [isVisible]
    )
}
