import Icon from '@mui/icons-material/Home'
import { grey } from '@mui/material/colors'
import { withThemeCreator } from '@mui/styles'
import { useMemo } from 'react'

export const HomeIcon = (props) => {
    return useMemo(
        () => <Icon sx={{ color: grey[50], marginX: '10px' }} {...props} />,
        [props]
    )
}
