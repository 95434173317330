import React, { useCallback, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { useAuth } from 'lib/auth'
import { useForm } from 'react-hook-form'

import { Grid, TextField, Button, Typography } from '@mui/material'
import {
    baseTextColor,
    buttonBaseColor,
    buttonHoverColor,
    buttonDisabledBgColor,
    buttonDisabledFontColor
} from 'constants/colors'
import { LoginComplite } from './LoginComplite'
import { LoginLoading } from './LoginLoading'

const PrimaryButton = styled(Button)`
    background-color: ${buttonBaseColor};
    box-size: border-box;
    color: white;
    font-weight: bold;
    height: 56px;
    :hover {
        background-color: ${buttonHoverColor};
    }
    &.Mui-disabled {
        background-color: ${buttonDisabledBgColor};
        color: ${buttonDisabledFontColor};
    }
`

const LoginTextField = styled(TextField)`
    & .MuiInput-root {
        caret-color: ${buttonBaseColor};
    }
    & .MuiInput-root:after {
        border-bottom: 2px solid ${buttonBaseColor};
    }
    & .MuiInputLabel-root.Mui-focused {
        color: ${buttonBaseColor};
    }
`

const Title = styled(Typography)`
    color: ${baseTextColor};
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 1.25px;
    text-align: center;
`

const ErrorText = styled(Typography)`
    color: red;
    padding: 8px;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 1.25px;
    text-align: center;
`

export const LoginForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm()
    const { login } = useAuth()
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const onSubmit = useCallback(
        async (data) => {
            setError(false)
            setSuccess(false)
            setLoading(true)
            try {
                await login({ user: data.user, password: data.password })
                setSuccess(true)
            } catch (error) {
                setError(true)
            } finally {
                setLoading(false)
            }
        },
        [login]
    )
    // console.log(watch('user'))

    return useMemo(() => {
        return (
            <>
                <LoginComplite success={success}>
                    <Grid container justifyContent='center' alignItems='center'>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            noValidate
                            autoComplete='off'
                        >
                            <Grid
                                container
                                height='320px'
                                justifyContent='center'
                                alignItems='center'
                            >
                                <Grid
                                    container
                                    height='30%'
                                    justifyContent='center'
                                    alignItems='center'
                                >
                                    <Title>
                                        メールに記載している
                                        <br />
                                        ID、パスワードを入力してください
                                    </Title>
                                    {error && (
                                        <ErrorText>
                                            IDまたはパスワードが間違っています
                                        </ErrorText>
                                    )}
                                </Grid>

                                <Grid
                                    container
                                    height='20%'
                                    justifyContent='center'
                                    alignItems='center'
                                    mx={2}
                                >
                                    {/* <Title title={title}/> */}
                                    <LoginTextField
                                        id='user'
                                        label='ID'
                                        fullWidth
                                        sx={{
                                            borderBottom: baseTextColor
                                        }}
                                        variant='standard'
                                        {...register('user', {
                                            required: true
                                        })}
                                    />
                                </Grid>

                                <Grid
                                    container
                                    height='20%'
                                    justifyContent='center'
                                    alignItems='center'
                                    mx={2}
                                >
                                    {/* <Title title={title}/> */}
                                    <LoginTextField
                                        id='password'
                                        label='パスワード'
                                        fullWidth
                                        variant='standard'
                                        {...register('password', {
                                            required: true
                                        })}
                                    />
                                    {errors.exampleRequired && (
                                        <span>This field is required</span>
                                    )}
                                </Grid>

                                <Grid
                                    container
                                    height='30%'
                                    justifyContent='center'
                                    alignItems='flex-end'
                                >
                                    {/* <Buttons buttons={buttons}/> */}
                                    <PrimaryButton
                                        type='submit'
                                        variant='contained'
                                        size='large'
                                        fullWidth
                                    >
                                        ログインする
                                    </PrimaryButton>
                                </Grid>

                                {/* TODO: QRコードの読み込み方法検討要
                    <Grid
                        container
                        height='10%'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Button
                            fullWidth
                            startIcon={<ScannerIcon />}
                            sx={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: buttonBaseColor,
                                paddingY: '20px',
                            }}
                        >
                            ＱＲコードでログインする
                        </Button>
                    </Grid> */}
                            </Grid>
                        </form>
                    </Grid>
                </LoginComplite>
                <LoginLoading loading={loading} />
            </>
        )
    }, [
        error,
        errors.exampleRequired,
        handleSubmit,
        loading,
        onSubmit,
        register,
        success
    ])
}
