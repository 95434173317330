import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { App } from 'components'
import reportWebVitals from './reportWebVitals'
import DocumentMeta from 'react-document-meta'
import SEO from './seo'

ReactDOM.render(
    <React.StrictMode>
        <DocumentMeta {...SEO}>
            <App />
        </DocumentMeta>
    </React.StrictMode>,
    document.getElementById('root')
)

reportWebVitals()
