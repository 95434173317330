import React, { useMemo } from 'react'

import { Grid } from '@mui/material'

import Webcam from 'react-webcam'
import { baseBackgroundColor } from 'constants/colors'
import styled from '@emotion/styled'

const BlankBox = styled(Grid)`
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
`

const Camera = ({ webcamRef, color }) => {
    return useMemo(
        () => (
            <Grid
                width='100%'
                height='100%'
                position='relative'
                zIndex={1}
                style={{
                    maxHeight: '100%',
                    overflow: 'hidden'
                }}
            >
                <Webcam
                    position='absolute'
                    top={0}
                    left={0}
                    audio={false}
                    height={'100%'}
                    ref={webcamRef}
                    screenshotFormat='image/jpeg'
                    videoConstraints={{
                        facingMode: 'environment'
                    }}
                    style={{
                        height: '100%',
                        width: '100%',
                        overflow: 'hidden',
                        objectFit: 'cover',
                        objectPosition: 'center'
                    }}
                />
                <BlankBox container top='0' height='25%'></BlankBox>
                <BlankBox container bottom='0' height='45%'></BlankBox>
                <Grid
                    container
                    position='absolute'
                    style={{
                        border: `8px solid ${color ?? baseBackgroundColor}`,
                        borderRadius: '6px',
                        boxSizing: 'border-box'
                    }}
                    top='25%'
                    left='16px'
                    height='30%'
                    width='calc(100% - 32px)'
                ></Grid>
                <BlankBox
                    container
                    top='25%'
                    left='0'
                    height='30%'
                    width='16px'
                ></BlankBox>
                <BlankBox
                    container
                    top='25%'
                    right='0'
                    height='30%'
                    width='16px'
                ></BlankBox>
            </Grid>
        ),
        [color, webcamRef]
    )
}

export default Camera
