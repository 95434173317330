import { Button } from '@mui/material'
import { useMemo } from 'react'

export const GuideSkipButton = ({ onClose, finished }) => {
    return useMemo(() => {
        return (
            <Button
                fullWidth
                onClick={onClose}
                sx={{
                    height: '100%',
                    marginLeft: '0px',
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '16px'
                }}
            >
                {finished ? 'はじめる' : 'スキップしてはじめる'}
            </Button>
        )
    }, [finished, onClose])
}
