import React, { useMemo } from 'react'
import styled from '@emotion/styled'

import { Grid } from '@mui/material'

const BackgroundGrid = styled(Grid)`
    background-color: white;
    height: 100vh;
    height: 100dvh;
`

const SmartPhoneGrid = styled(Grid)`
    padding-left: 8px;
    padding-right: 8px;
`

export const Layout = (props) => {
    return useMemo(
        () => (
            <BackgroundGrid
                container
                justifyContent='center'
                alignItems='center'
            >
                <SmartPhoneGrid
                    container
                    width='400px'
                    height='100%'
                    sx={{
                        backgroundColor: props.bg || 'black',
                        color: props.color || 'white',
                        ...props.sx
                    }}
                >
                    {props.children}
                </SmartPhoneGrid>
            </BackgroundGrid>
        ),
        [props.bg, props.color, props.children, props.sx]
    )
}
