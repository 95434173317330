import CameraAltIcon from '@mui/icons-material/CameraAlt'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import DefaultChar from 'assets/default.png'
import ErrorChar from 'assets/error.png'
import { useMemo } from 'react'
import {
    analysisDefaultColor,
    analysisErrorColor,
    analysisLoadingColor,
    analysisSuccessColor
} from 'constants/colors'

const useCaptureContent = ({
    loading,
    success,
    error,
    playing,
    handleCapture,
    handlePlay
}) => {
    const handleChanges = useMemo(() => {
        if (loading) {
            return {
                title: '分析中…',
                color: analysisLoadingColor,
                charactor: DefaultChar,
                texts: ['写真を分析しているよ', 'ちょっと待っててね！'],
                buttons: [
                    {
                        text: '撮影する',
                        onClick: handleCapture,
                        disabled: true,
                        icon: <CameraAltIcon />
                    }
                ]
            }
        }
        if (success) {
            if (playing) {
                return {
                    title: '再生中…',
                    color: analysisSuccessColor,
                    charactor: DefaultChar,
                    texts: ['再生が終わるまで', 'ちょっと待っててね！'],
                    buttons: [
                        {
                            text: '撮り直す',
                            onClick: handleCapture,
                            disabled: true,
                            icon: <CameraAltIcon />
                        },
                        {
                            text: '再生中',
                            onClick: handlePlay,
                            disabled: false,
                            icon: <VolumeUpIcon />
                        }
                    ]
                }
            } else {
                return {
                    title: '分析できたよ！',
                    color: analysisSuccessColor,
                    charactor: DefaultChar,
                    texts: ['やったね！🎉', 'さっそく再生してみよう♪'],
                    buttons: [
                        {
                            text: '撮り直す',
                            onClick: handleCapture,
                            disabled: false,
                            icon: <CameraAltIcon />
                        },
                        {
                            text: '再生する',
                            onClick: handlePlay,
                            disabled: false,
                            icon: <PlayCircleIcon />
                        }
                    ]
                }
            }
        }
        if (error) {
            return {
                title: '分析できませんでした',
                color: analysisErrorColor,
                charactor: ErrorChar,
                texts: ['あれれ？', 'もう一度撮ってみよう！'],
                buttons: [
                    {
                        text: '撮り直す',
                        onClick: handleCapture,
                        disabled: false,
                        icon: <CameraAltIcon />
                    }
                ]
            }
        }
        return {
            title: '枠に合わせて撮影してね！',
            color: analysisDefaultColor,
            charactor: DefaultChar,
            texts: [
                'いろんな色があるほど',
                'いろんな音が出やすくなって',
                'おもしろいよ♪'
            ],
            buttons: [
                {
                    text: '撮影する',
                    onClick: handleCapture,
                    disabled: false,
                    icon: <CameraAltIcon />
                }
            ]
        }
    }, [loading, success, error, playing, handleCapture, handlePlay])
    return { ...handleChanges }
}

export default useCaptureContent
