const meta = {
    title: 'プログラミングはかせのひみつきち',
    description: 'プログラミングはかせたちと一緒にあそぼう！',
    canonical: 'https://picture-book.aigram.jp/',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: ''
        }
    }
}

export default meta
