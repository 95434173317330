import Icon from '@mui/icons-material/Login'
import { buttonBaseColor } from 'constants/colors'
import { useMemo } from 'react'

export const LoginIcon = (props) => {
    return useMemo(
        () => (
            <Icon sx={{ color: buttonBaseColor, marginX: '10px' }} {...props} />
        ),
        [props]
    )
}
