import React, { useMemo } from 'react'

import { Grid, Typography } from '@mui/material'
import DefaultChar from 'assets/default.png'
import ErrorChar from 'assets/error.png'

const Text = ({ texts, charactor }) => {
    return useMemo(
        () => (
            <>
                <Grid item xs={4} height='100%'>
                    <Grid
                        container
                        justifyContent='center'
                        alignItems='center'
                        height='100%'
                    >
                        <img
                            src={charactor}
                            alt='メインキャラクター'
                            height='70%'
                            style={{ maxHeight: '80px' }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={8} height='100%'>
                    <Grid
                        container
                        direction='column'
                        justifyContent='center'
                        alignItems='left'
                        height='100%'
                    >
                        {texts.map((text) => (
                            <Typography
                                color='white'
                                style={{ fontWeight: 'bold' }}
                                key={text}
                            >
                                {text}
                            </Typography>
                        ))}
                    </Grid>
                </Grid>
            </>
        ),
        [texts, charactor]
    )
}

export default Text
