export const baseTextColor = '#6C6267'
export const baseBackgroundColor = '#ff9ccf'
export const buttonBaseColor = '#ef5da8'
export const buttonHoverColor = '#a42667'
export const buttonDisabledBgColor = '#dcdcdc'
export const buttonDisabledFontColor = '#858789'
export const dialogBorderColor = '#c4c4c4'
export const analysisErrorColor = '#ff8298'
export const analysisSuccessColor = '#5ce5c5'
export const analysisLoadingColor = '#ffe185'
export const analysisDefaultColor = '#ff9ccf'
export const analysisLoadingTitleColor = '#ffe600'
