const backend_url = {
    analyze: 'https://picture-book-be.aigram.jp/analyze/analyze',
    login: 'https://picture-book-be.aigram.jp/auth/login'
    //analyze: 'http://localhost:8100/analyze/analyze',
}

const image2File = (imageSrc) => {
    const bin = atob(imageSrc.replace(/^.*,/, ''))
    const buffer = new Uint8Array(bin.length)
    for (let i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i)
    }
    const image_file = new File([buffer.buffer], 'image.jpg', {
        type: 'image/jpeg'
    })

    return image_file
}

const resizeImage = (src) => {
    return new Promise(function (resolve, reject) {
        //Imageオブジェクトを生成
        const image = new Image()

        //読み込む画像を設定（関数の引数で取得した画像パスを代入）
        image.src = src

        //onloadで画像読み込み完了後に処理
        image.onload = function () {
            const data = {
                width: image.naturalWidth,
                height: image.naturalHeight
            }
            const ratio = window.screen.height / window.screen.width
            const position = {
                x: Math.max(
                    0,
                    (data.width - Math.round(data.width / ratio)) / 2
                ),
                y: Math.round(data.height * 0.25),
                width: Math.min(data.width, Math.round(data.height / ratio)),
                height: Math.round(data.height * 0.3)
            }
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            canvas.width = position.width
            canvas.height = position.height
            ctx.drawImage(
                image,
                position.x,
                position.y,
                position.width,
                position.height,
                0,
                0,
                position.width,
                position.height
            )
            canvas.toBlob(async (blob) => {
                //成功時にthenの処理へ
                resolve({
                    url: canvas.toDataURL('image/jpeg'),
                    downloadUrl: URL.createObjectURL(blob),
                    blob: blob,
                    ...data
                })
            }, 'image/jpeg')
        }

        //onerrorで画像読み込みエラー時に処理
        image.onerror = function (error) {
            //失敗時にcatchの処理へ
            reject({ message: error })
        }
    })
}

export { backend_url, image2File, resizeImage }
