import React from 'react'
import styled from '@emotion/styled'

import { analyze } from 'funcs/apis'
import { get } from 'lodash'

import { resizeImage } from 'funcs/utils'

const useAnalyze = () => {
    const webcamRef = React.useRef(null)
    const [player, setPlayer] = React.useState(new Audio())
    const [message, setMessage] = React.useState('')
    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [playing, setPlaying] = React.useState(false)

    const handleCapture = React.useCallback(async () => {
        try {
            setLoading(true)
            setSuccess(false)
            setError(false)

            // console.log('cap')

            const imageSrc = webcamRef.current?.getScreenshot()
            // const image_file = image2File(imageSrc)
            const image_file = await resizeImage(imageSrc)
            const result = await analyze(image_file.blob)

            if (!result.err) {
                setSuccess(true)
                // console.log('result', result)
                const url = get(result, 'data.mp3')
                const text = get(result, 'data.text')
                setMessage(text)
                setPlayer(new Audio(url))
            } else {
                setError(true)
            }
        } finally {
            setLoading(false)
        }
    }, [webcamRef])

    const handlePlay = React.useCallback(async () => {
        try {
            setPlaying(true)
            await player.play()
        } catch (err) {
            setPlaying(false)
        }
    }, [player])

    React.useEffect(() => {
        if (success) {
            player.addEventListener('ended', () => {
                // console.log('ended')
                setPlaying(false)
            })
        }
        return () => {
            player.removeEventListener('ended', () => {
                // console.log('ended')
                setPlaying(false)
            })
        }
    }, [player, success])

    return {
        handleCapture,
        handlePlay,
        loading,
        success,
        error,
        playing,
        webcamRef,
        message
    }
}

export default useAnalyze
