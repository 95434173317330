import React, { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'

import { Box, Button, Grid } from '@mui/material'
import { Layout } from 'components/layout'
import { AppButton } from 'components/button'
import { Splash } from 'components/splash'
import { LogoutIcon } from 'components/icon'
import { useAuth } from 'lib/auth'
import { useNavigate } from 'react-router-dom'
import { baseBackgroundColor } from 'constants/colors'
import helloChar from 'assets/hello.png'

const Container = () => {
    const { logout } = useAuth()
    const navigator = useNavigate()
    const handleLogout = useCallback(() => {
        logout()
        navigator('/logout')
    }, [logout, navigator])
    return useMemo(
        () => (
            <Layout bg={baseBackgroundColor}>
                <Grid container justifyContent='center' alignItems='center'>
                    <Grid
                        container
                        height='20%'
                        justifyContent='space-between'
                        alignItems='center'
                    ></Grid>
                    <Grid
                        container
                        height='60%'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <AppButton
                            href='/capture'
                            char={helloChar}
                            texts={['おしゃべり', 'ペーパーちゃん']}
                        />
                        <AppButton texts={['Coming soon...']} disabled={true} />
                    </Grid>
                    <Grid
                        container
                        height='20%'
                        justifyContent='center'
                        alignItems='start'
                    >
                        <Box>
                            <Button
                                onClick={handleLogout}
                                startIcon={<LogoutIcon />}
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: 'white',
                                    paddingY: '20px'
                                }}
                            >
                                ログアウト
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Splash />
            </Layout>
        ),
        [handleLogout]
    )
}

export default Container
