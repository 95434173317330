import React, { useMemo } from 'react'
import styled from '@emotion/styled'

import { Grid } from '@mui/material'
import { BaseButton } from './button'

const Buttons = ({ buttons }) => {
    return useMemo(
        () => (
            <>
                {buttons.map((button, index) => (
                    <Grid item xs key={index}>
                        <BaseButton
                            startIcon={button.icon || null}
                            onClick={() => button.onClick()}
                            disabled={button.disabled}
                            type={
                                buttons.length > 1 && index % 2 === 0
                                    ? 'secondary'
                                    : 'primary'
                            }
                        >
                            {button.text}
                        </BaseButton>
                    </Grid>
                ))}
            </>
        ),
        [buttons]
    )
}

export default Buttons
