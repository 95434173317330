import { backend_url } from 'funcs/utils'

import axios from 'axios'
import { get } from 'lodash'
import { tokenStorage } from 'lib/main'

export const analyze = async (image) => {
    const formData = new FormData()
    const token = tokenStorage.get()
    formData.append('image', image, image.name)
    formData.append('charLimit', '15')

    return await call(backend_url.analyze, formData, {
        headers: {
            token: `${token}`
        }
    })
}

const call = async (endpoint, formData, config = {}) => {
    try {
        const res = await axios({
            method: 'POST',
            url: endpoint,
            ...config,
            data: formData
        })

        return {
            data: res.data,
            err: false
        }
    } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
            const detail = get(e.response, 'data.detail')

            // console.log(e.response.status)
            // console.log('detail', detail)

            let msg = ''
            if (typeof detail === typeof '') {
                msg = detail
            } else if (typeof detail === typeof []) {
                if (detail.length > 0) {
                    msg = get(detail[0], 'msg', '')
                }
            }

            return {
                data: e.response.data,
                err: true,
                msg: msg
            }
        } else {
            return {
                err: true,
                msg: '不明なエラーが発生しました'
            }
        }
    }
}

export default call
