import * as React from 'react'
import styled from '@emotion/styled'

import { Grid, Typography, Stack } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { baseTextColor, buttonBaseColor } from 'constants/colors'

const AppSwiper = styled(Swiper)`
    height: 100%;

    .swiper-button-prev,
    .swiper-button-next {
        color: ${buttonBaseColor};
        &:after {
            font-size: 20px;
            font-weight: bold;
        }
    }
    .swiper-button-prev {
        left: -5px;
    }
    .swiper-button-next {
        right: -5px;
    }
    .swiper-pagination-bullet-active {
        background-color: ${buttonBaseColor};
    }
`

const ImageBox = styled(Grid)`
    width: auto;
    height: 45%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 100%;
        width: auto;
    }
`

export const GuideSlide = (props) => {
    const { dataList, ...parentProps } = props

    return React.useMemo(
        () => (
            <>
                <AppSwiper {...parentProps}>
                    {dataList &&
                        dataList.map((guide, index) => (
                            <SwiperSlide key={index}>
                                <Stack
                                    height='100%'
                                    direction='column'
                                    justifyContent='space-around'
                                    alignItems='center'
                                >
                                    <Grid
                                        container
                                        height='50px'
                                        justifyContent='center'
                                        alignItems='center'
                                    >
                                        {guide.texts.map((text, index) => (
                                            <Grid
                                                key={index}
                                                container
                                                justifyContent='center'
                                                alignItems='center'
                                            >
                                                <Typography
                                                    fontFamily='Hiragino Kaku Gothic Pro'
                                                    fontSize='16px'
                                                    fontWeight='bold'
                                                    color={baseTextColor}
                                                >
                                                    {text}
                                                </Typography>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <ImageBox>
                                        {guide.image ? (
                                            <img
                                                src={guide.image}
                                                alt='ガイド画像'
                                            />
                                        ) : (
                                            '※IMG'
                                        )}
                                    </ImageBox>
                                    <Grid
                                        container
                                        height='50px'
                                        justifyContent='center'
                                        alignItems='center'
                                    ></Grid>
                                </Stack>
                            </SwiperSlide>
                        ))}
                </AppSwiper>
            </>
        ),
        [dataList, parentProps]
    )
}
