import { initReactQueryAuth } from 'react-query-auth'
import { http, tokenStorage } from './main'
import { backend_url } from "funcs/utils"

async function loadUser() {
    const token = tokenStorage.get()

    if (token) {
        return { token }
    }

    return null
}

async function loginFn(req) {
    const data = new FormData()
    data.append('userId', req.user)
    data.append('password', req.password)

    const res = await http.post(backend_url.login, data)

    const { token } = res.data
    tokenStorage.set(token)

    return req.user
}

async function registerFn() {}

async function logoutFn() {
    tokenStorage.clear()
}

const authConfig = {
    loadUser,
    loginFn,
    registerFn,
    logoutFn,
    waitInitial: false,
}

const { AuthProvider, useAuth } = initReactQueryAuth(authConfig)

export { AuthProvider, useAuth }
