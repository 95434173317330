import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'

import { fabClasses, Fade, Grid, Typography } from '@mui/material'
import { baseTextColor } from 'constants/colors'
import check from 'assets/check.png'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'lib/auth'

const Title = styled(Typography)`
    color: ${baseTextColor};
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 1.25px;
    text-align: center;
`

export const LoginComplite = ({ success, children }) => {
    const { user } = useAuth()
    const [isLoading, setIsLoading] = useState(false)
    const checkUserLogin = useCallback(
        (loading, func) => {
            if (success && user) {
                if (loading) {
                    func()
                } else {
                    setIsLoading(true)
                }
            }
        },
        [success, user]
    )
    useEffect(() => {
        // console.log('isLoading', isLoading)
        checkUserLogin(isLoading, () => {
            setTimeout(() => {
                window.location.reload()
            }, 5000)
        })
    }, [checkUserLogin, isLoading])
    return useMemo(
        () =>
            !user ? (
                children
            ) : (
                <Fade appear={false} in={isLoading} timeout={3000}>
                    <Grid container justifyContent='center' alignItems='center'>
                        <Fade appear={false} in={isLoading} timeout={2000}>
                            <Grid
                                container
                                height='200px'
                                justifyContent='center'
                                alignItems='center'
                            >
                                <Grid
                                    container
                                    height='20%'
                                    paddingBottom='50px'
                                    justifyContent='center'
                                    alignItems='center'
                                >
                                    <Title>ログインできました！</Title>
                                </Grid>
                                <Grid
                                    container
                                    height='20%'
                                    paddingBottom='50px'
                                    justifyContent='center'
                                    alignItems='center'
                                >
                                    <img
                                        src={check}
                                        alt='メインキャラクター'
                                        height='52px'
                                        style={{ maxHeight: '80px' }}
                                    />
                                </Grid>

                                <Grid
                                    container
                                    height='40%'
                                    justifyContent='center'
                                    alignItems='center'
                                    mx={2}
                                >
                                    <Grid
                                        container
                                        justifyContent='center'
                                        alignItems='center'
                                    >
                                        <Title>おめでとうございます🎉</Title>
                                        <Title>
                                            自動的にホーム画面へ移動します
                                        </Title>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Fade>
                    </Grid>
                </Fade>
            ),
        [children, isLoading, user]
    )
}
