import React, { useCallback, useMemo, useState } from 'react'
import styled from '@emotion/styled'

import { Box, Grid, Skeleton, Typography } from '@mui/material'
import { Title, Camera, Buttons, Text } from 'components'
import { HomeIcon } from 'components/icon'
import { HelpIcon } from 'components/icon/help'
import { CloseDialog } from 'components/close'
import { Layout } from 'components/layout'
import { UserGuideDialog } from 'components/guide/UserGuide'
import useUserGuide from 'hooks/useUserGuide'
import {
    analysisLoadingColor,
    analysisLoadingTitleColor
} from 'constants/colors'

const SkeletonText = styled(Skeleton)`
    font-size: 21px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 1.25px;
    transform: none;
    color: ${analysisLoadingTitleColor};
`

const Container = ({
    title,
    texts,
    buttons,
    charactor,
    color,
    loading,
    webcamRef,
    message
}) => {
    const [open, setOpen] = useState(false)
    const [openGuide, onOpenGuide, onCloseGuide] = useUserGuide()

    const handleClickOpen = useCallback(() => {
        setOpen(true)
    }, [setOpen])

    const handleClose = useCallback(
        (value) => {
            setOpen(false)
        },
        [setOpen]
    )

    return useMemo(
        () => (
            <Layout
                sx={{
                    paddingLeft: '0px !important',
                    paddingRight: '0px !important'
                }}
            >
                <Box container height='100%' width='100%' position='relative'>
                    <Box
                        position='absolute'
                        top={0}
                        left={0}
                        boxSizing='border-box'
                        paddingX={1.5}
                        zIndex={5}
                        height='100%'
                        width='100%'
                    >
                        <Grid
                            container
                            height='100%'
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Grid
                                container
                                height='7%'
                                justifyContent='space-between'
                                alignItems='center'
                            >
                                <Box onClick={handleClickOpen}>
                                    <HomeIcon />
                                </Box>
                                <Box onClick={onOpenGuide}>
                                    <HelpIcon />
                                </Box>
                            </Grid>

                            <Grid
                                container
                                height='8%'
                                justifyContent='center'
                                alignItems='flex-start'
                            >
                                {loading ? (
                                    <SkeletonText>{title}</SkeletonText>
                                ) : (
                                    <Title title={title} />
                                )}
                            </Grid>
                            <Grid
                                container
                                height='10%'
                                justifyContent='space-between'
                                alignItems='flex-start'
                                color={analysisLoadingColor}
                            >
                                {message && (
                                    <>
                                        <Typography>【</Typography>
                                        <Typography>{message}</Typography>
                                        <Typography>】</Typography>
                                    </>
                                )}
                            </Grid>
                            <Grid container height='30%' direction='row'></Grid>

                            <Grid container height='20%' direction='row'>
                                <Text texts={texts} charactor={charactor} />
                            </Grid>

                            <Grid
                                container
                                height='20%'
                                direction='row'
                                spacing={1.5}
                            >
                                <Buttons buttons={buttons} />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box
                        position='absolute'
                        top={0}
                        left={0}
                        height='100%'
                        width='100%'
                    >
                        <Camera
                            webcamRef={webcamRef}
                            color={color}
                            message={message}
                        />
                    </Box>
                </Box>
                <CloseDialog open={open} onClose={handleClose} />
                <UserGuideDialog open={openGuide} onClose={onCloseGuide} />
            </Layout>
        ),
        [
            handleClickOpen,
            handleClose,
            onOpenGuide,
            onCloseGuide,
            open,
            openGuide,
            title,
            texts,
            buttons,
            charactor,
            color,
            loading,
            webcamRef,
            message
        ]
    )
}

export default Container
