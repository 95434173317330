import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import { Box, DialogActions, DialogContent, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
    baseTextColor,
    buttonBaseColor,
    dialogBorderColor
} from 'constants/colors'
import SleepChar from 'assets/sleep.png'

export const CloseDialog = (props) => {
    const { onClose, open } = props
    const navigator = useNavigate()
    const handleClose = React.useCallback(() => {
        onClose()
    }, [onClose])

    const handleExit = React.useCallback(() => {
        onClose()
        navigator('/')
    }, [onClose, navigator])

    return React.useMemo(
        () => (
            <Dialog fullWidth maxWidth='xs' onClose={handleClose} open={open}>
                <DialogTitle
                    fontSize='16px'
                    fontWeight='bold'
                    color={baseTextColor}
                    sx={{ textAlign: 'center', paddingTop: '24px' }}
                >
                    アプリを終了しますか？
                </DialogTitle>
                <DialogContent>
                    <Grid container justifyContent='center' alignItems='center'>
                        <img
                            src={SleepChar}
                            alt='おやすみ'
                            height='100%'
                            style={{ maxHeight: '150px' }}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions
                    sx={{
                        padding: 0,
                        height: '56px',
                        border: `1px solid ${dialogBorderColor}`
                    }}
                    disableSpacing={true}
                >
                    <Button
                        fullWidth
                        autoFocus
                        onClick={handleClose}
                        sx={{
                            height: '100%',
                            marginLeft: '0px',
                            borderRight: `1px solid ${dialogBorderColor}`,
                            borderRadius: '0',
                            color: baseTextColor,
                            fontWeight: 'bold',
                            fontSize: '16px'
                        }}
                    >
                        まだ続ける
                    </Button>
                    <Button
                        fullWidth
                        onClick={handleExit}
                        sx={{
                            height: '100%',
                            marginLeft: '0px',
                            borderRadius: '0',
                            color: buttonBaseColor,
                            fontWeight: 'bold',
                            fontSize: '16px'
                        }}
                    >
                        終了する
                    </Button>
                </DialogActions>
            </Dialog>
        ),
        [handleClose, handleExit, open]
    )
}

CloseDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
}
