import { Layout } from 'components/layout'
import { LoginForm } from 'components/user/LoginForm'
import { useAuth } from 'lib/auth'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const Container = () => {
    const { user, login } = useAuth()
    const navigator = useNavigate()
    const location = useLocation()
    const query = useMemo(() => {
        const search = location.search
        const queryData = new URLSearchParams(search)
        return queryData
    }, [location.search])
    const checkQueryParam = useCallback(async () => {
        if (!user && query.get('id') && query.get('pass')) {
            try {
                await login({
                    user: query.get('id'),
                    password: query.get('pass')
                })
                window.location.reload()
            } catch (error) {
                // console.log(error)
                navigator('/login')
            }
        }
    }, [login, navigator, query, user])
    useEffect(() => {
        // console.log('Param', query.get('id'))
        if (user?.token) {
            navigator('/')
        } else {
            checkQueryParam()
        }
    }, [user, navigator, location, query, login, checkQueryParam])
    return useMemo(
        () => (
            <Layout bg='white'>
                <LoginForm />
            </Layout>
        ),
        []
    )
}

export default Container
