import React, { useCallback, useEffect } from 'react'

const useUserGuide = (prefix = 'user_guide') => {
    const [openUserGuide, setOpenUserGuide] = React.useState(false)

    const handleOpen = useCallback(() => {
        setOpenUserGuide(true)
    }, [])

    const handleClose = useCallback(() => {
        setOpenUserGuide(false)
    }, [])

    useEffect(() => {
        if (localStorage.getItem(prefix) !== 'true') {
            localStorage.setItem(prefix, 'true')
            setOpenUserGuide(true)
        }
    }, [openUserGuide, prefix])
    return [openUserGuide, handleOpen, handleClose]
}

export default useUserGuide
