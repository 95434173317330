import { Layout } from 'components/layout'
import { LogoutComplite } from 'components/user/LogoutComplite'
import React, { useMemo } from 'react'

const Container = () => {
    return useMemo(
        () => (
            <Layout bg='white'>
                <LogoutComplite />
            </Layout>
        ),
        []
    )
}

export default Container
